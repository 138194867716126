@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- /
/ Actions Toolbar
/* ---------------------------------------------------------------------- */

$actions-toolbar-height: 44px;

.actions-toolbar-wrapper {
  bottom: $space-large;
  left: 0;
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;
  padding: 0 $space;
  position: fixed;
  right: 0;
  z-index: 1000;
}

.actions-toolbar {
  align-items: stretch;
  background: $color-blue-100;
  border-radius: $border-radius;
  color: #fff;
  display: flex;
  height: $actions-toolbar-height;
  margin-left: calc(#{$sidebar-width} + #{$space});

  > div:first-child,
  > div:last-child {
    align-items: center;
    display: flex;
    gap: $space;
    padding: $space-small;
  }

  > div:first-child {
    flex-shrink: 0;
    padding-right: $space;
  }

  > div:last-child {
    flex: 1;
    overflow: hidden;
    overflow-x: auto;
    padding-left: $space;

    svg {
      height: $space;
      width: $space;
    }
  }
}

.actions-toolbar-divider {
  align-self: center;
  background: #fff;
  height: $actions-toolbar-height - (2 * $space-small);
  width: 1px;
}

.hover-actions {
  align-items: center;
  bottom: 0;
  display: flex;
  gap: $space;
  margin-right: $space-small;
  position: absolute;
  right: 0;
  top: 0;
  svg {
    height: $space;
    width: $space;
    &:hover {
      color: $color-sapphire-100;
    }
  }
}
