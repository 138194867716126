@use 'sass:math';
@import '../../_variables.scss';

$pill-icon-size: 16px;

/* ---------------------------------------------------------------------- */
/* Pill
/* ---------------------------------------------------------------------- */

.pill {
  align-items: center;
  background-color: var(--color-surface-light);
  border-radius: $border-radius;
  color: var(--color-text-default);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  gap: math.div($space-small, 2);
  height: 22px;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  padding: 0 math.div($space-small, 2);
  text-align: left;

  &:focus-visible {
    @extend %outline-offset;
  }

  > strong {
    margin-top: -2px;
  }

  & + & {
    margin-left: math.div($space-small, 2);
  }

  &.is-active {
    background-color: var(--color-button-primary-default);
    color: #fff;
  }

  &.is-completed {
    cursor: none;
    pointer-events: none;
  }

  &.is-disabled {
    color: var(--color-text-faint);
    pointer-events: none;
  }
}

.pill-close-icon {
  height: $pill-icon-size;
  width: $pill-icon-size;
}

.pill-icon {
  line-height: 0;

  svg {
    height: $pill-icon-size;
    width: $pill-icon-size;
  }

  .is-error:not(.is-active) & {
    color: var(--color-text-error);
  }
}

.pill-required-icon {
  font-weight: 700;
  margin-top: -2px;

  .pill:not(.is-active) & {
    color: var(--color-text-error);
  }
}
