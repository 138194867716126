@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* ITEM_COLUMNS
/* ---------------------------------------------------------------------- */

.pre-data-wave {
  svg {
    color: $color-blue-100;
  }
}
