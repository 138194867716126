@use 'sass:math';
@import '../../_variables.scss';

.notification {
  background-color: var(--color-surface-default);
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color: var(--color-text-default);
}

.notification-content {
  > div {
    border-left: math.div($space-small, 2) solid transparent;
    padding-left: $space-small;

    &.is-success {
      border-color: $color-mint-100;
    }

    &.is-error {
      border-color: var(--color-text-error);
    }

    &.is-info {
      border-color: $color-blue-100;
    }

    &.is-warn {
      border-color: $color-yellow-100;
    }
  }
}
