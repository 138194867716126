@use 'sass:math';

/* ---------------------------------------------------------------------- */
/* Colors
/* ---------------------------------------------------------------------- */

$color-neutral-50: #eff4f4; // Azure/Featherweight
$color-neutral-75: #e0e9ea; // Flyweight
$color-neutral-100: #b3c9cb; // Bantamweight
$color-neutral-300: #669297; // Welterweight
$color-neutral-700: #134a51; // Does not exist in Figma
$color-neutral-900: #002c31; // Sumo

$color-blue-100: #007aff;

$color-green-50: #b0ffd5;
$color-green-75: #4aff9f;
$color-green-100: #00e36b;
$color-green-300: #00b053;
$color-green-500: #007d3b;
$color-green-700: #004a23;

$color-magenta-100: #f621fb;

$color-mint-100: #0aefb8;

$color-orange-100: #fe971f;

$color-purple-100: #8053ff;

$color-red-50: #ffe2e8;
$color-red-100: #ea526f;
$color-red-300: #b02641;
$color-red-500: #810c23;
$color-red-900: #4b0411;

$color-sapphire-50: #85f4ff;
$color-sapphire-75: #1fecff;
$color-sapphire-100: #00a8b8;
$color-sapphire-100-transparent: rgba(0, 168, 184, 0.25); // Transparent variant of Sapphire 100
$color-sapphire-300: #007985;
$color-sapphire-500: #004b52;
$color-sapphire-900: #001c1f;

$color-yellow-50: #fef6d1;
$color-yellow-100: #ffd61e;

$gradient-sapphire-mint: linear-gradient(
  226.66deg,
  $color-sapphire-100-transparent 2.76%,
  rgba(10, 239, 184, 0.25) 100.08%
);

/* ---------------------------------------------------------------------- */
/* Font Families
/* ---------------------------------------------------------------------- */

$font-family-mark-web-pro: 'FFMarkWebPro', Helvetica, Arial, sans-serif;

/* ---------------------------------------------------------------------- */
/* Font Weights
/* ---------------------------------------------------------------------- */

$font-weight-bold: 700;

/* ---------------------------------------------------------------------- */
/* Max Width
/* ---------------------------------------------------------------------- */

$max-width: 1333px;

/* ---------------------------------------------------------------------- */
/* Min Width
/* ---------------------------------------------------------------------- */

$min-width: 1024px;

/* ---------------------------------------------------------------------- */
/* Border Radius
/* ---------------------------------------------------------------------- */

$border-radius: 3px;

/* ---------------------------------------------------------------------- */
/* Box Shadow
/* ---------------------------------------------------------------------- */

$box-shadow-inset: 0px 0px 0px 1px var(--color-border-subdued) inset;
$box-shadow-shallow: 0px 1px 3px 0px var(--color-border-subdued);
$box-shadow: 0px 4px 7px 0px var(--color-border-subdued);
$box-shadow-deep: 0px 12px 22px 0px var(--color-border-subdued);

$elevation-100:
  0px 1px 3px 0px rgba(var(--color-elevation-rgb), 0.2),
  0px 0px 1px 0px rgba(var(--color-elevation-rgb), 0.35);
$elevation-200:
  0px 4px 7px 0px rgba(var(--color-elevation-rgb), 0.15),
  0px 0px 1px 0px rgba(var(--color-elevation-rgb), 0.35);
$elevation-300:
  0px 12px 22px 0px rgba(var(--color-elevation-rgb), 0.12),
  0px 0px 1px 0px rgba(var(--color-elevation-rgb), 0.35);

/* ---------------------------------------------------------------------- */
/* Button Size
/* ---------------------------------------------------------------------- */

$button-height: 40px;

/* ---------------------------------------------------------------------- */
/* Icon Size
/* ---------------------------------------------------------------------- */

$icon-size: 24px;
$tag-size: 12px;

/* ---------------------------------------------------------------------- */
/* Layout
/* ---------------------------------------------------------------------- */

$sidebar-max-width: 300px;
$sidebar-width: math.percentage(math.div(1, 5));

/* ---------------------------------------------------------------------- */
/* Space
/* ---------------------------------------------------------------------- */

$space-small: 10px;
$space: 15px;
$space-large: 20px;

/* ---------------------------------------------------------------------- */
/* Transition Duration
/* ---------------------------------------------------------------------- */

$transition-duration: 0.15s;

/* ---------------------------------------------------------------------- */
/* Placeholders
/* ---------------------------------------------------------------------- */

%input {
  background-color: var(--color-surface-light);
  border: 1px solid var(--color-border-default);
  border-radius: $border-radius;
  color: var(--color-text-default);
  font-family: $font-family-mark-web-pro;
  font-size: inherit;
  height: $button-height;
  padding: $space-small;
  min-width: 320px;

  &::placeholder {
    color: var(--color-text-subdued);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    background-color: var(--color-surface-default);
    border-color: var(--color-border-focus);
    outline: none;

    &::placeholder {
      color: var(--color-text-faint);
    }
  }
}

%label {
  color: var(--color-text-subdued);
  display: block;
  font-size: 12px;
  font-weight: initial;
  margin-bottom: math.div($space-small, 4);
  text-align: left;
  text-transform: uppercase;
}

%outline-offset {
  outline: var(--color-border-focus) solid 2px;
  outline-offset: 2px;
}

%table {
  width: 100%;

  thead {
    background: var(--color-surface-default);
    color: var(--color-text-subdued);
    position: sticky;
    text-transform: uppercase;
    top: 0;
    user-select: none;
    z-index: 30;

    > tr {
      border-bottom: 1px solid var(--color-border-default);
    }
  }

  tbody {
    > tr {
      border-bottom: 1px solid var(--color-border-subdued);
    }
  }

  tfoot {
    > tr:not(:last-child) {
      border-bottom: 1px solid var(--color-border-subdued);
    }
  }

  th,
  td {
    &:first-child {
      text-align: left;
      &:not(:last-child) {
        border-right: 1px solid var(--color-border-subdued);
      }
    }
  }

  th {
    font-size: 12px;
    padding: $space-small;

    &.is-sortable {
      cursor: pointer;
      &:hover {
        color: var(--color-text-default);
      }
    }
  }

  td {
    font-size: 14px;
    padding: math.div($space-small, 2) $space-small;
  }
}
