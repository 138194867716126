@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question
/* ---------------------------------------------------------------------- */

.question-actions {
  align-items: center;
  color: var(--color-text-faint);
  display: flex;
}

.question-answer {
  display: flex;
  gap: $space-small;
  padding: 0 $space;

  > span {
    word-break: break-word;
  }

  > svg {
    height: $space;
    margin-top: math.div($space-small, 4);
    min-width: $space + $space-small;
  }
}

.question-answer-image {
  margin-bottom: $space-small;
  max-height: 125px;
  max-width: 200px;
}

.question-answer-body {
  display: flex;
  flex-flow: column;
}

.question-body {
  align-items: center;
  display: flex;
  gap: $space;
  justify-content: center;
}

.question-container {
  background: var(--color-surface-default);
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow-shallow;
  gap: $space;
  padding: $space $space-small;
  width: $min-width * 0.75;
}

.question-detailed-view {
  color: var(--color-text-default);
  display: flex;
  flex-flow: column;
  padding-left: $space-large * 3;
  padding-top: $space;
}

.question-drag-handle {
  color: var(--color-text-faint);
  padding-top: math.div($space, 5);
}

.question-draggable-container {
  margin-bottom: $space * 2;
}

.question-image {
  margin-bottom: $space * 2;
  margin-top: $space-small;
  max-width: 200px;
  max-height: 125px;
}

.question-number-container {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 0 $space-small;
  width: $space-large * 3;
}

.question-open-ended {
  font-style: italic;
  padding: 0 $space;
}

.question-text {
  align-items: center;
  color: var(--color-text-default);
  display: flex;
  flex: 1;
  word-break: break-word;
}
