@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Tabs
/* ---------------------------------------------------------------------- */

.tabs {
  align-items: flex-end;
  border-bottom: 1px solid var(--color-border-subdued);
  display: flex;
  margin-bottom: $space;
  margin-top: math.div(-$space, 2);

  > li {
    align-items: center;
    display: flex;
    flex: 0 1;
    white-space: nowrap;
  }

  > li + li {
    margin-left: $space * 2;
  }

  > li:first-child > a {
    margin-left: 0;
  }

  > li > a,
  > li > span {
    display: inline-block;
    color: var(--color-text-subdued);
    cursor: pointer;
    padding: math.div($space, 2) 0;
    position: relative;
    text-decoration: none;

    :global(.docs-story) & {
      pointer-events: none;
    }
  }

  > li.is-active {
    > a,
    > span {
      color: var(--color-text-default);

      &:after {
        background-color: var(--color-text-link);
        content: '';
        height: 3px;
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
      }
    }
  }

  > li.is-locked {
    opacity: 0.5;
    pointer-events: none;

    > svg {
      color: var(--color-text-subdued);
      margin-left: $space-small;
      min-height: 0;
      min-width: 0;
    }
  }
}
